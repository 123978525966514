<template>
  <div class="player">
    <div class="player_content">
      <img class="logo" :src="cover" />
      <div class="program">{{ title }}</div>
      <div class="title">{{ podCast.title }}</div>
      <div class="description">{{ podCast.description }}</div>
      <div class="time">{{ podCast.time }}</div>
      <div class="tools">
        <div class="icon primary"
             :class="{ active: active }"
             v-on:click.prevent="play">
          <Icon :type="`${(active) ? 'stop' : 'play'}`" color="white" />
        </div>
        <PodCastDownload :pod_cast="podCast" />
      </div>
      <div class="close" v-on:click.prevent="close">Parar e fechar</div>
    </div>
  </div>
</template>

<script>
import PodCastDownload from './PodCastDownload.vue';
import Icon from './Icon.vue';

export default {
  name: 'Player',
  data() {
    return {
      active: false,
      audio: null,
    };
  },
  props: {
    podCast: Object,
    cover: String,
    title: String,
  },
  components: {
    PodCastDownload,
    Icon,
  },
  created() {
    this.play();
  },
  methods: {
    play() {
      if (!this.audio) {
        this.audio = new Audio(this.podCast.url_audio);
      }
      if (this.active) {
        this.audio.pause();
        this.active = false;
      } else {
        this.audio.play();
        this.active = true;
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    close() {
      if (this.active) {
        this.audio.pause();
        this.active = false;
      }
      this.$emit('inactive');
    },
  },
};
</script>

<style scoped>
.player {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0,0,0,.8);
  z-index: 1000;
}
.player_content{
  width: 400px;
  height: auto;
  position: fixed;
  top: 100px;
  left: calc((100% - 400px) / 2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: lightgray;
}
.logo{
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 60px;
  object-fit: cover;
  border: 2px solid orange;
}
.title,
.program {
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  margin-top: 10px;
}
.title {
  font-size: 24px;
}
.description {
  font-size: 16px;
  color: white;
  margin-top: 5px;
}
.time {
  font-size: 16px;
  color: gray;
  margin-top: 5px;
}
.tools {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}
.icon {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid lightgray;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: lightgray;
  text-align: center;
  cursor: pointer;
  transition: all .3s ease-in-out;
  position: relative;
}
.icon:hover {
  transition: all .3s ease-in-out;
  transform: scale3d(1.07, 1.07, 1.07);
}
.icon.primary {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: orange;
  border: 1px solid orange;
  color: white;
  text-align: center;
}
@keyframes rotateIcon {
  from { transform: rotate(0) }
  to { transform: rotate(360deg) }
}
.icon.active:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-top: 3px solid #f3f3f3;
  border-radius: 50%;
  animation: rotateIcon infinite 1s linear;
}
.icon.primary.active:before {
  border-top: 3px solid darkorange;
}
.close {
  height: 30px;
  padding: 0 20px;
  border-radius: 15px;
  border: 1px lightgray solid;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 26px;
  cursor: pointer;
}
.close:hover {
  opacity: .6;
}
</style>
