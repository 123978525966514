<template>
  <div class="programs" key="programList">
    <div class="filter">
      <input placeholder="Digite algo para filtrar..."
             title="program_search" class="program_search"
             type="search"
             v-model="searchText"
      />
      <div class="clear_search"
           :class="{ active: filtered }"
           v-on:click.prevent="clearSearch"
      >Mostrar todos</div>
    </div>
    <Loading v-if="!programList.length" />
    <ProgramList v-else :programList="programListFiltered" />
  </div>
</template>

<style scoped>
  .programs{
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .filter {
    position: relative;
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .program_search {
    width: 100%;
    height: 60px;
    display: block;
    border-radius: 30px;
    border: 4px solid #ffa946;
    background: #f3f3f3;
    color: black;
    padding: 5px 15px;
    outline: none;
  }
  .program_search::placeholder {
    color: #ffa946;
    font-weight: 100;
    letter-spacing: 1px;
  }
  .clear_search {
    padding: 5px 20px;
    border-radius: 15px;
    height: 30px;
    text-transform: uppercase;
    color: white;
    font-size: 14px;
    line-height: 18px;
    position: absolute;
    right: 10px;
    top: 29px;
    background-color: orange;
    transform: translate3d(80px, 0, 0);
    opacity: 0;
    transition: all ease-in-out .3s;
    cursor: pointer;
  }
  .clear_search.active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: all ease-in-out .3s;
  }
</style>

<script>
import ProgramList from '../components/ProgramList.vue';
import Loading from '../components/Loading.vue';

export default {
  name: 'Programs',
  data() {
    return {
      searchText: '',
      programList: [],
    };
  },
  created() {
    this.loadPrograms();
  },
  methods: {
    loadPrograms() {
      this.axios.get('https://www.clube92.com.br/_api/podcasts/')
        .then((result) => {
          this.programList = result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prepareToSearch(text) {
      return text.toLowerCase().replace(/[^\w\s]/gi, '');
    },
    clearSearch() {
      this.searchText = '';
    },
  },
  computed: {
    programListFiltered() {
      const { programList, searchText } = this;

      if (!programList.length) return [];
      if (!searchText.length) return programList;

      const newSearchText = this.prepareToSearch(searchText);
      return programList.filter((item) => {
        const title = this.prepareToSearch(item.title);
        const content = title.search(newSearchText);
        if (content >= 0) return item;
        return false;
      });
    },
    filtered() {
      return this.searchText.length > 0;
    },
  },
  components: {
    ProgramList,
    Loading,
  },
};
</script>
