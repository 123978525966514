<template>
  <div class="program">
    <header>
      <img class="program-img" :src="program.cover" />
      <div class="program-content" v-on:click.prevent="goToProgram(program.alias)">
        <div class="detail">
          <h3>{{program.title}}</h3>
          <p>{{program.description}}</p>
        </div>
      </div>
      <div class="program-action">
        <PodCastPlay v-if="firstPodCast && !widget"
                     :program="program" :pod_cast="firstPodCast" :primary="true" />
        <div v-if="!complete && !widget" class="icon colapsed" v-on:click.prevent="showPodCasts">
          <Icon :type="(opened) ? 'chevron-up' : 'chevron-down'" color="" />
        </div>
      </div>
    </header>
    <ul class="podcast_list" v-if="opened || complete || widget">
      <PodCast v-for="podCast in program.pod_casts"
               :key="podCast.id" :pod-cast="podCast" :program="program" :widget="widget" />
      <li v-if="!complete" class="load-more"
          v-on:click.prevent="goToProgram(program.alias)">Visualizar mais antigos</li>
    </ul>
  </div>
</template>

<script>
import PodCast from './PodCast.vue';
import PodCastPlay from './PodCastPlay.vue';
import Icon from './Icon.vue';

export default {
  name: 'Program',
  data() {
    return {
      opened: false,
    };
  },
  props: {
    program: Object,
    complete: {
      default: false,
      type: Boolean,
    },
    widget: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    showPodCasts() {
      this.opened = !this.opened;
    },
    goToProgram(id) {
      if (this.widget) {
        if (this.program.url) window.open(this.program.url, '_self');
        return;
      }
      if (this.complete) {
        this.$router.back();
        return;
      }
      this.$router.push({ name: 'Program', params: { pod_cast_id: id } });
    },
  },
  computed: {
    firstPodCast() {
      if (!this.program.pod_casts.length) return null;
      return this.program.pod_casts[0];
    },
  },
  components: {
    PodCast,
    PodCastPlay,
    Icon,
  },
};
</script>

<style scoped>
.program {
  padding: 0;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
}
header {
  position: relative;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}
header:before {
  content: "";
  width: calc(100% + 10px);
  bottom: 0;
  position: absolute;
  top: 0;
  left: -5px;
  background-image: linear-gradient(35deg, #ffb60f, #ffd449);
  transform: rotateZ(-1deg);
  z-index: -1;
  border-radius: 40px;
}
.program-img {
  display: inline-flex;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
  background: #2248a5;
  object-fit: cover;
  border: 4px solid orange;
}
.program-content {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  text-align: left;
  flex: 1;
  padding-left: 10px;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  cursor: pointer;
}
.program-action {
  position: relative;
  display: inline-flex;
  width: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}
.program-action .icon {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #2248a5;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: lightgray;
  text-align: center;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
.program-action .icon:hover {
  transition: all .3s ease-in-out;
  transform: scale3d(1.07, 1.07, 1.07);
}
.program-action .icon.primary {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: orange;
  border: 1px solid orange;
  color: white;
  text-align: center;
}
.detail {
  display: inline-flex;
  flex-direction: column;
  color: #2248a5;
}
.detail * {
  padding: 0;
  margin: 0;
}
.detail h3 {
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
}
.detail p {
  line-height: 100%;
}
.podcast_list {
  width: calc(100% - 20px);
  margin: 10px 10px 60px;
}
.load-more {
  padding: 5px 20px;
  border: 2px solid lightgray;
  border-radius: 20px;
  text-align: center;
  color: gray;
  cursor: pointer;
  opacity: .7;
  transition: .3s linear opacity;
}
.load-more:hover {
  opacity: 1;
  transition: .3s linear opacity;
}
</style>
