<template>
  <ul class="programList">
    <li v-for="program in programList" :key="program.id">
      <Program :program="program" />
    </li>
    <li v-if="!programList">
      <NoProgram  />
    </li>
  </ul>
</template>

<script>
import Program from './Program.vue';
import NoProgram from './NoProgram.vue';

export default {
  name: 'ProgramList',
  props: {
    programList: Array,
  },
  components: {
    Program,
    NoProgram,
  },
};
</script>

<style scoped>

</style>
