import { render, staticRenderFns } from "./NoProgram.vue?vue&type=template&id=546a2ff9&scoped=true&"
import script from "./NoProgram.vue?vue&type=script&lang=js&"
export * from "./NoProgram.vue?vue&type=script&lang=js&"
import style0 from "./NoProgram.vue?vue&type=style&index=0&id=546a2ff9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546a2ff9",
  null
  
)

export default component.exports