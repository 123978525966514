<template>
  <div class="pod-cast" :class="{ widget: widget }">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffd449" fill-opacity="1" d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    <div class="pod-info">
      <p>{{ podCast.title }}</p>
      <span>{{ podCast.description }}</span>
    </div>
    <div class="pod-action">
      <PodCastPlay :program="program" :pod_cast="podCast" :primary="false" />
      <PodCastDownload :pod-cast="podCast" />
    </div>
  </div>
</template>

<script>
import PodCastPlay from './PodCastPlay.vue';
import PodCastDownload from './PodCastDownload.vue';

export default {
  name: 'PodCast',
  props: {
    podCast: Object,
    program: Object,
    widget: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    PodCastPlay,
    PodCastDownload,
  },
};
</script>

<style scoped>
.pod-cast {
  width: 100%;
  height: 60px;
  padding: 10px 10px 10px 30px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-image: linear-gradient(135deg, #ffd449, #fff3c6);
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 10px;
}
.pod-cast svg {
  display: block;
  position: absolute;
  top: -75px;
  left: 0;
}
.pod-cast:last-child {
  border-bottom: none;
}
.pod-action,
.pod-info {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.pod-action{
  flex: none;
  justify-content: center;
  align-items: center;
}
.widget .pod-info {
  flex-direction: column;
}
p {
  line-height: 100%;
  flex: 1;
  text-align: left;
  font-weight: bold;
  color: #2248a5;
  z-index: 1;
}
span {
  flex: 1;
  color: #2248a5;
  line-height: 100%;
  text-align: left;
  z-index: 1;
}
</style>
