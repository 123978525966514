import Vue from 'vue';
import VueRouter from 'vue-router';
import PodCasts from '../views/Programs.vue';
import Program from '../views/Program.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'PodCasts',
    component: PodCasts,
  },
  {
    path: '/:pod_cast_id',
    name: 'Program',
    component: Program,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
