<template>
  <div id="widget">
    <Loading v-if="!program" />
    <Program v-else :program="program" :widget="true" />
  </div>
</template>

<style>
#widget{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>

<script>
import Program from './components/Program.vue';
import Loading from './components/Loading.vue';

export default {
  name: 'Widget',
  data() {
    return {
      program: false,
    };
  },
  created() {
    this.getProgramData();
  },
  methods: {
    getProgramData() {
      const { programAlias } = this.$root.$el.dataset;
      if (!programAlias) {
        window.addEventListener('load', this.getProgramData);
        return;
      }

      this.axios.get(`https://www.clube92.com.br/_api/podcast/?p=${programAlias}&mode=widget`)
        .then((result) => {
          this.program = result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    Program,
    Loading,
  },
};
</script>
