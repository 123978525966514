import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import App from './App.vue';
import Widget from './Widget.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');

new Vue({
  render: (h) => h(Widget),
}).$mount('#widget');
