<template>
  <div class="object">
    <i :class="`${type} ${color}`"></i>
  </div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    type: {
      default: 'play',
      type: String,
    },
    color: {
      default: 'write',
      type: String,
    },
  },
};
</script>

<style scoped>
.object {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
i {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
i.play:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid #ffd449;
  transition: all linear .15s;
}
i.stop:before {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid #2248a5;
  transition: all linear .15s;
}
i.stop.write:before {
  border: 8px solid white;
}
i.play.write:before {
  border-left: 10px solid white;
}
i.chevron-down:before {
  content: "";
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #2248a5;
  border-right: 2px solid #2248a5;
  transform: rotate(45deg);
  transition: all linear .15s;
}
i.chevron-up:before {
  content: "";
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #2248a5;
  border-right: 2px solid #2248a5;
  transform: rotate(-135deg);
  transition: all linear .15s;
}
i.chevron-left:before {
  content: "";
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #2248a5;
  border-right: 2px solid #2248a5;
  transform: rotate(135deg);
  transition: all linear .15s;
}
i.chevron-down.write:before,
i.chevron-up.write:before,
i.chevron-left.write:before{
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}
i.download:before {
  content: "";
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #2248a5;
  border-right: 2px solid #2248a5;
  transform: rotate(45deg);
  transition: all linear .15s;
  position: absolute;
}
i.download:after {
  content: "";
  width: 2px;
  height: 10px;
  background: #2248a5;
  transition: all linear .15s;
  position: absolute;
}
</style>
