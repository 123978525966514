<template>
  <div class="pod-cast-download">
    <div class="icon"
         v-on:click.prevent="download">
      <Icon type="download" color="" />
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'PodCastDownload',
  props: {
    podCast: Object,
  },
  methods: {
    download() {
      this.axios.get(`https://www.clube92.com.br/_api/podcast/count/?id=${this.podCast.alias}`);
      window.open(this.podCast.url_audio, '_blank');
    },
  },
  components: {
    Icon,
  },
};
</script>

<style scoped>
.pod-cast-play {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #2248a5;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: lightgray;
  text-align: center;
  cursor: pointer;
  transition: all .3s ease-in-out;
  position: relative;
}
.icon:hover {
  transition: all .3s ease-in-out;
  transform: scale3d(1.07, 1.07, 1.07);
}
.icon.primary {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: orange;
  border: 1px solid orange;
  color: white;
  text-align: center;
}
@keyframes rotateIcon {
  from { transform: rotate(0) }
  to { transform: rotate(360deg) }
}
.icon.active:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-top: 3px solid #f3f3f3;
  border-radius: 50%;
  animation: rotateIcon infinite 1s linear;
}
.icon.primary.active:before {
  border-top: 3px solid darkorange;
}
</style>
