<template>
  <div class="program" :key="$route.params.pod_cast_id">
    <header>
      <router-link to="/">
        <Icon type="chevron-left" color="" />
      </router-link>
    </header>
    <Loading v-if="!program" />
    <Program v-else :program="program" :complete="true" />
  </div>
</template>

<style scoped>
header{
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
header a{
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #2248a5;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: lightgray;
  text-align: center;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
</style>

<script>
import Program from '../components/Program.vue';
import Icon from '../components/Icon.vue';
import Loading from '../components/Loading.vue';

export default {
  name: 'Programs',
  data() {
    return {
      program: false,
    };
  },
  created() {
    this.getProgramData();
  },
  methods: {
    getProgramData() {
      this.axios.get(`https://www.clube92.com.br/_api/podcast/?p=${this.$route.params.pod_cast_id}`)
        .then((result) => {
          this.program = result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    Program,
    Icon,
    Loading,
  },
};
</script>
