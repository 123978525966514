<template>
  <div id="app">
    <div class="graph-detail">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 314"><path fill="#ffffff" fill-opacity="1" d="M0,192L48,202.7C96,213,192,235,288,202.7C384,171,480,85,576,58.7C672,32,768,64,864,85.3C960,107,1056,117,1152,106.7C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    </div>
    <transition name="slide-fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<style>
#app{
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.graph-detail {
  display: block;
  position: absolute;
  left: 0;
  padding: 0;
  top: 450px;
  width: 100%;
  overflow: hidden;
  background-color: #ffd449;
  z-index: -10;
}
.graph-detail svg {
  display: block;
}
</style>

<script>
export default {
  name: 'Application',
};
</script>
