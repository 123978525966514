<template>
  <div class="no-program">
    <h1>Desculpe nenhum programa para exibir</h1>
  </div>
</template>

<script>
export default {
  name: 'Program',
  props: {
    program: Object,
  },
};
</script>

<style scoped>
.no-program {
  width: 100%;
  height: 140px;
  border: 1px solid lightgray;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-image: linear-gradient(30deg, lightgray, white);
}
h1{
  color: white;
  font-size: 16px;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: normal;
  padding: 5px 30px;
  background: orange;
  border-radius: 20px;
}
</style>
